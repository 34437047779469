@charset "UTF-8";
/* .jumbotron {
  background-image: url("https://kaboompics.com/cache/7/3/7/1/b/7371b7a8de0b38d8906cdae62a3374526d4109d0.jpeg");
  background-size: cover;
  height: 30rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: 0.5s ease;
} */
.jumbotron-slice-1 {
  background-image: url("https://cdn.philoart.io/b/1200x1200/ejt2Vbza56UViZTf2vEHY.jpg");
  background-size: cover;
  height: 30rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: 0.5s ease;
}

.jumbotron-slice-2 {
  background-image: url("https://pbs.twimg.com/media/FALwz4FVEAkrVNV?format=jpg&name=4096x4096");
  background-size: cover;
  height: 30rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: 0.5s ease;
}

.jumbotron-slice-3 {
  background-image: url("https://cdn.philoart.io/7/1200x1200/a4h1Gv0lrVlZ5lF4cfS_C.jpg");
  background-size: cover;
  height: 30rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: 0.5s ease;
}

.jumbotron-header {
  font-size: 3rem;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  color: white;
  transition: 0.5s ease;
}

.jumbotron-subheader {
  font-size: 1.5rem;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  color: white;
  transition: 0.5s ease;
}

@media screen and (max-width: 800px) {
  .jumbotron-slice-1 {
    height: 25rem;
  }

  .jumbotron-slice-2 {
    height: 25rem;
  }

  .jumbotron-slice-3 {
    height: 25rem;
  }

  .jumbotron {
    height: 20rem;
  }

  .jumbotron .header {
    font-size: 2rem;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-weight: bold;
    color: white;
  }

  .jumbotron .subheader {
    font-size: 1rem;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    color: white;
  }
}
.container-row-searchbar {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
}

.jumbotron .searchbar input[type=text] {
  flex-grow: 1;
  border: none;
  border-radius: 5px;
  font-size: 20px;
  width: 100%;
  padding: 8px 20px;
  margin-left: 10px;
}

.jumbotron .searchbar .container-row-searchbar {
  background-color: #5f5a5a;
  border: none;
  flex-grow: 1;
  border-radius: 5px;
}

.search-button {
  float: left;
  display: block;
  color: black;
  text-align: center;
  text-decoration: none;
  font-size: 15px;
  margin-right: 15px;
}

/* Style The Dropdown Button */
.searchbar .dropbtn {
  background-color: transparent;
  color: black;
  width: 5rem;
  padding: 20px;
  font-size: 16px;
  border-radius: 5px;
  border: none;
}

/* The container <div> - needed to position the dropdown content */
.searchbar .dropdown {
  position: relative;
  display: inline-block;
}

.searchbar .dropdown-content {
  display: none;
  position: absolute;
  width: 5rem;
  background-color: #f9f9f9;
  border-radius: 5px;
  z-index: 1;
}

.searchbar .dropdown-content button {
  display: block;
  background-color: white;
  width: 5rem;
  padding: 10px;
  font-size: 15px;
  border: none;
  border-radius: 5px;
}

.searchbar .dropdown-content button:hover {
  background-color: #f1f1f1;
}

.searchbar .dropdown:hover .dropdown-content {
  display: block;
}

.searchbar .icon-search {
  font-size: 1.5rem;
  /* margin-top: 10%; */
  font-weight: bold;
  color: #777;
  background-color: transparent;
}

.searchbar .search-btn {
  text-align: center;
  background-color: transparent;
  font-weight: bold;
  color: #f1f1f1;
  background-color: transparent;
  border: none;
  border-radius: 100%;
  width: 5rem;
  height: 3.5rem;
  margin: 10px, 20px;
  transition: 0.5s ease;
}

.searchbar .search-btn:hover {
  background-color: #f1f1f1;
}

/*forest green */
.navbar-button-join {
  font-size: 18px;
  font-weight: 700;
  color: white;
  background-color: forestgreen;
  border: none;
  border-radius: 5px;
  padding: 2px 10px;
  transition: ease 0.2s;
}

.navbar-button-join:hover {
  color: white;
  background-color: #4cbb17;
  /* -webkit-box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15); */
}

.navbar-button-logout {
  font-weight: light;
  color: black;
  background-color: transparent;
  border-radius: 5px;
  border-width: 1px;
  padding: 2px 10px;
  margin-right: 5px;
  transition: ease 0.2s;
}

.navbar-button-logout:hover {
  color: lightgrey;
  -webkit-box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}

.navbar-link {
  font-weight: 500;
  color: black;
  background-color: transparent;
  border: none;
  padding: 8px 10px;
  transition: ease 0.2s;
}

.navbar-link:hover {
  color: lightgrey;
}

.container-row-navbar {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-row-navbar-brand {
  justify-content: center;
  align-items: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  color: black;
}

.container-row-navbar-searchbox {
  flex-grow: 1;
  border-color: black;
  border-width: 1px;
  border-radius: 5px;
}

.container-row-navbar-searchbox input[type=text] {
  font-size: 15px;
  width: 100%;
  padding: 8px 20px;
}

.container-row-searchpage-searchbox {
  border: 2px;
}

.container-row-searchpage-searchbox input[type=text] {
  border-radius: 5px;
  font-size: 20px;
  width: 100%;
  padding: 8px 20px;
}

.collection-dropbtn {
  background-color: white;
  color: black;
  width: auto;
  font-size: 16px;
  margin-right: 35px;
  border: none;
  cursor: pointer;
}

.user-collection-list-title-padding-left {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 2rem;
}

.user-collection-list-title {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.user-collection-list-title-center {
  align-self: flex-end;
  justify-content: flex-end;
}

.user-collection-list-btn {
  align-self: flex-end;
  padding-right: 0.5rem;
}

.container-user-collection-list-title {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  padding-top: 0.5rem;
}

.photo-list-container {
  display: flex;
  flex-direction: column;
  align-content: center;
  margin-top: 1rem;
  margin-bottom: 5rem;
}

.the-end {
  display: flex;
  flex-direction: column;
  align-content: center;
}

.the-end-title {
  display: flex;
  flex-direction: column;
  align-content: center;
  font-size: 1.2rem;
  font-weight: bold;
  color: #777;
  text-align-last: center;
  margin-top: 10%;
  margin-left: 15px;
  margin-right: 15px;
  background-color: white;
  border: 2px solid lightgray;
  padding: 0.5rem 1.5rem;
  transition: ease 0.2s;
}

.my-masonry-grid {
  display: -webkit-box;
  /* Not needed if autoprefixing */
  display: -ms-flexbox;
  /* Not needed if autoprefixing */
  display: flex;
  margin-left: -15px;
  /* gutter size offset */
  width: auto;
}

.my-masonry-grid_column {
  padding-left: 15px;
  /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  background: transparent;
  margin-bottom: 15px;
}

.photo-card {
  position: relative;
  display: flex;
  align-items: flex-end;
  width: 100%;
  height: 100%;
}

.photo-card-btn1 {
  position: absolute;
  text-align: center;
  margin-top: 30px;
  top: -2rem;
  right: 0.5rem;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.photo-card-btn2 {
  position: absolute;
  text-align: center;
  margin-top: 30px;
  top: -2rem;
  right: 3rem;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.photo-card-btn3 {
  position: absolute;
  text-align: center;
  margin-top: 30px;
  top: -2rem;
  right: 5.5rem;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.photo-card-btn4 {
  position: absolute;
  text-align: center;
  margin-top: 30px;
  top: -2rem;
  right: 8rem;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.photo-card-btn5 {
  position: absolute;
  text-align: start;
  margin-top: 30px;
  top: -2rem;
  right: 10.5rem;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.photo-card-btn-web {
  position: absolute;
  text-align: center;
  margin-top: 30px;
  font-size: 1rem;
  top: -1.5rem;
  right: 5.5rem;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.photo-card:hover .photo-card-btn1 {
  opacity: 1;
}

.photo-card:hover .photo-card-btn2 {
  opacity: 1;
}

.photo-card:hover .photo-card-btn3 {
  opacity: 1;
}

.photo-card:hover .photo-card-btn4 {
  opacity: 1;
}

.photo-card:hover .photo-card-btn5 {
  opacity: 1;
}

.photo-card:hover .photo-card-btn-web {
  opacity: 1;
}

.photo-card-img {
  position: relative;
  width: 100%;
  height: 100%;
  left: 0;
}

/* When the screen is less than 600px wide, make photo button visible */
@media screen and (max-width: 600px) {
  .photo-card:hover .photo-card-btn-web {
    opacity: 0;
  }

  .photo-card-btn1 {
    font-size: 1.5rem;
    right: 0.5rem;
    opacity: 1;
  }

  .photo-card-btn2 {
    font-size: 1.5rem;
    right: 2.5rem;
    opacity: 1;
  }

  .photo-card-btn3 {
    font-size: 1.5rem;
    right: 4.5rem;
    opacity: 1;
  }

  .photo-card-btn4 {
    font-size: 1.5rem;
    right: 6.5rem;
    opacity: 0;
  }

  .photo-card:hover .photo-card-btn4 {
    opacity: 0;
  }

  .photo-card-btn5 {
    font-size: 1.5rem;
    right: 8.5rem;
    opacity: 0;
  }

  .photo-card:hover .photo-card-btn5 {
    opacity: 0;
  }
}
.photo-card-btn-icon {
  font-size: 1.5rem;
  margin-top: 10%;
  font-weight: bold;
  color: white;
  background-color: transparent;
  border: none;
  cursor: pointer;
  width: auto;
  height: auto;
}

.photodetails-card-btn-like {
  font-size: 1.3rem;
  margin-top: 10%;
  font-weight: light;
  background-color: white;
  border: 1px solid black;
  border-radius: 5px;
  padding: 0.5rem 1.5rem;
  transition: ease 0.2s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.photodetails-card-btn-like:hover {
  background-color: #f1f1f1;
}

.photodetails-card-btn-collect {
  font-size: 1.3rem;
  margin-top: 10%;
  font-weight: light;
  background-color: white;
  border: 1px solid black;
  border-radius: 5px;
  padding: 0.5rem 1.5rem;
  transition: ease 0.2s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.photodetails-card-btn-collect:hover {
  background-color: #f1f1f1;
}

.photodetails-card-btn-download {
  font-size: 1.3rem;
  margin-top: 10%;
  font-weight: light;
  color: white;
  background-color: #3d833d;
  border: none;
  border-radius: 5px;
  padding: 0.5rem 1.5rem;
  transition: ease 0.2s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.photodetails-card-btn-download:hover {
  background-color: #60ad60;
  -webkit-box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}

.photodetails-card-btn-download-disabled {
  font-size: 1.3rem;
  margin-top: 10%;
  font-weight: light;
  color: white;
  background-color: rgba(151, 151, 151, 0.963);
  border: none;
  border-radius: 5px;
  padding: 0.5rem 1.5rem;
  transition: ease 0.2s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.photodetails-card-btn-info {
  font-size: 1rem;
  margin-top: 10%;
  font-weight: light;
  background-color: white;
  border: 1px solid black;
  border-radius: 5px;
  padding: 0.2rem 1rem;
  transition: ease 0.2s;
}

.photodetails-card-btn-item {
  margin: 0.5rem;
}

.photodetails-photo-item {
  margin-bottom: 1rem;
  margin-top: 1rem;
  padding: 0.2rem 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-row-primary {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-top: 14px;
}

.photo-details-author-name {
  display: flex;
  flex-flow: row;
  align-items: flex-start;
  color: black;
  padding-top: 8px;
  font-size: 1rem;
  padding-top: 10px;
  padding: 8px 10px;
}

.photo-details-author-avatar {
  display: flex;
  flex-flow: row;
  align-items: center;
  margin-top: 3px;
  margin-left: 10px;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
}

.photo-details-date {
  display: flex;
  flex-flow: row;
  align-items: flex-end;
  color: #777;
  padding-top: 12px;
  font-size: 0.9rem;
  padding: 8px 10px;
}

.container-photo-title {
  width: 100%;
  max-width: 300px;
  /* 可根据需要进行调整 */
  margin: 0 auto;
  text-align: left;
}

.photo-title {
  font-size: 24px;
  padding-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.photo-description {
  font-size: 16px;
  text-align: left;
}

.colorbox {
  width: 100px;
  height: 30px;
}

.colorbox-colorname {
  color: white;
  text-align: center;
}

.container-details-title-btn {
  display: flex;
  margin-top: 30px;
  justify-content: center;
  align-items: center;
}

.container-details-title-btn > * {
  margin: 0 30px;
  /* 设置元素间距，如果需要的话 */
}

.container-details-title-btn > :first-child {
  margin-left: 0;
  /* 如果需要取消第一个元素的左侧间距 */
  width: 300px;
}

.container-details-title-btn > :last-child {
  margin-right: 0;
  /* 如果需要取消最后一个元素的右侧间距 */
}

.container-col-license {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 5rem;
  margin-bottom: 5rem;
  margin-left: 5%;
  margin-right: 5%;
}

.license {
  background-image: url("https://images.unsplash.com/photo-1448375240586-882707db888b?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1500&q=80");
  background-size: cover;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.license-header-bold-white {
  font-weight: bold;
  color: white;
}

.license-subheader {
  color: white;
}

.license-header-bold {
  font-weight: bold;
}

.license-icon-check {
  padding: 10px 20px;
  font-size: 2rem;
  color: cornflowerblue;
  width: 50%;
}

.license-icon-x {
  padding: 10px 20px;
  font-size: 2rem;
  color: crimson;
  width: 50%;
}

.license-msg-container {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 800px) {
  .license-header-bold-white {
    font-size: 1.5rem;
  }

  .license-header-bold {
    font-size: 1.5rem;
  }

  .license-subheader {
    font-size: 1rem;
  }

  .license-subheader-footer {
    font-size: 1rem;
  }

  .license-msg {
    font-size: 1rem;
  }

  .license-icon-check {
    font-size: 1.5rem;
  }

  .license-icon-x {
    font-size: 1.5rem;
  }
}
.about .header {
  font-size: 3rem;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  color: #777;
}

.about .subheader {
  font-size: 2rem;
  font-weight: bold;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.about .subheader2 {
  font-size: 1.5rem;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.about .profile-details {
  font-size: 1rem;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.about .about-footer {
  font-size: 1rem;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.container-about {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.container-about-row-reverse {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
}

.container-col-about-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.container-col-about {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10%;
  margin-left: 10%;
  margin-right: 10%;
}

.container-row-about {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 10%;
}

container-col-about-icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 800px) {
  .about .header {
    font-size: 2rem;
    color: #777;
  }

  .about .subheader {
    font-size: 1.5rem;
    font-weight: bold;
  }

  .about .subheader2 {
    font-size: 1rem;
  }

  .about .icon-check {
    font-size: 1rem;
  }
}
.button-follow {
  font-weight: light;
  color: white;
  background-color: rgba(0, 0, 0, 0.85);
  border-radius: 5px;
  border-width: 1px;
  padding: 2px 10px;
  margin-right: 5px;
  transition: ease 0.2s;
}

.button-follow:hover {
  color: lightgrey;
  -webkit-box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}

.button-unfollow {
  font-weight: light;
  color: black;
  background-color: transparent;
  border-radius: 5px;
  border-width: 1px;
  padding: 2px 10px;
  margin-right: 5px;
  transition: ease 0.2s;
}

.button-unfollow:hover {
  color: lightgrey;
  -webkit-box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}

.user-description {
  padding: 1rem;
  color: rgba(0, 0, 0, 0.75);
}

.button-follow:disabled {
  font-weight: light;
  color: black;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border-width: 1px;
  padding: 2px 10px;
  margin-right: 5px;
  transition: ease 0.2s;
}

.discover-author-list a {
  color: black;
}

/* The container <div> - needed to position the dropdown content */
.license-btn .dropdown {
  position: relative;
  display: inline-block;
}

.license-btn .dropdown-content {
  display: none;
  position: absolute;
  width: 10rem;
  background-color: #f9f9f9;
  border-radius: 5px;
  z-index: 1;
}

.license-btn .dropdown-content button {
  display: block;
  background-color: white;
  width: 10rem;
  padding: 10px;
  font-size: 15px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.license-btn .dropdown-content button:hover {
  background-color: #f1f1f1;
}

.license-btn .dropdown:hover .dropdown-content {
  display: block;
}

.container-row-license {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.container-row-license-item {
  align-self: flex-end;
  padding: 1rem;
}

.license-btn-info {
  font-size: 1rem;
  margin-top: 10%;
  font-weight: light;
  background-color: white;
  border: 1px solid black;
  border-radius: 5px;
  transition: ease 0.2s;
}

.container-row-license {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.container-row-license-item {
  align-self: flex-end;
  padding: 1rem;
}

.license-btn-info {
  font-size: 1rem;
  margin-top: 10%;
  font-weight: light;
  background-color: white;
  border: 1px solid black;
  border-radius: 5px;
  transition: ease 0.2s;
}

.margin-tb-2rem {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.dropbtn {
  font-size: 1rem;
  margin-top: 10%;
  width: 10rem;
  font-weight: light;
  background-color: white;
  border: 1px solid black;
  border-radius: 5px;
  transition: ease 0.2s;
}

body {
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  background-color: white;
  font-size: 1rem;
}

.discover .header {
  font-size: 3rem;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  color: #777;
  transition: 0.5s ease;
}

.discover .subheader p {
  font-size: 2rem;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  color: #777;
  transition: 0.5s ease;
}

.margin-tb-2rem {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.wrapper {
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: flex-end;
  align-items: flex-end;
}

.container-profile {
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-item {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem;
}

.button-0 {
  align-self: flex-end;
}

.button-1 {
  display: flex;
  justify-content: center;
  margin-right: 1rem;
}

.item-0 {
  flex-grow: 1;
  flex-shrink: 0;
}

.container-col-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.col-item-0 {
  margin: 0.5rem;
}

.col-item-1 {
  margin-top: 1rem;
}

.col-item-2 {
  margin-top: 1rem;
  display: flex;
  align-items: center;
}

.col-item-3 {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.col-item-4 {
  margin-top: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-col-signup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.col-item-signup {
  margin: 0.5rem;
  width: 100%;
}

.container-col-collection-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.col-item-collection-title {
  margin-top: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.col-item-collection-description {
  margin-top: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

input {
  width: 100%;
  padding: 8px 20px;
  margin: 8px 0px;
}

input:invalid {
  border: 1px solid red;
}

.error {
  color: red;
}

.container-social-row {
  display: flex;
  justify-content: center;
  align-items: center;
}

.item-social-icon {
  margin: 0.5rem;
  /* font-size: 1.5rem; */
}

.container-row-0 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-row-1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.container-row-3 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-row-2 {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-top: 100%;
}

.container-row-4 {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: flex-end;
}

.container-row-5 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 3rem;
}

.container-row-6 {
  display: flex;
  flex-direction: row;
}

.container-row-photodetail-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 1rem;
}

.container-row-signup {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.margin-right {
  margin-right: 1rem;
}

.row-item-0 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem;
}

.row-item-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10;
}

.row-item-2 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
}

.row-item-4 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.row-item-3 {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.row-item-5 {
  justify-content: center;
  align-items: center;
}

.row-item-6 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.container-collection-title {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-content: center;
}

.item-0-collection-title {
  order: 0;
  flex-grow: 1;
  flex-shrink: 0;
}

.item-1-collection-title {
  align-self: flex-end;
}

.container-col-settings {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-left: 20%;
  margin-right: 20%;
}

.container-create-collection-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
}

.create-collection-row-item {
  display: flex;
  justify-content: start;
  align-items: flex-start;
}

.create-collection-btn {
  font-size: 1.2rem;
  font-weight: light;
  color: white;
  background-color: #3d833d;
  border: none;
  margin: 8px 0;
  margin-left: 10px;
  border-radius: 5px;
  padding: 0.5rem 1.5rem;
  transition: ease 0.2s;
}

.create-collection-btn:hover {
  background-color: #60ad60;
  -webkit-box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}

.green-icon {
  color: yellowgreen;
}

.red-icon {
  color: crimson;
}

.icon-search {
  font-size: 1rem;
}

.icon-list {
  font-size: 1.2rem;
}

.social-icon-btn {
  font-size: 1.5rem;
  font-weight: bold;
  color: white;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.social-icon-btn-dark {
  font-size: 3rem;
  font-weight: bold;
  color: grey;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.margin-right {
  margin-right: 0.4rem;
}

.photo-card-btn-icon:hover {
  color: #c9c7c7;
}

.search-photo-card-btn-icon:hover {
  color: #c9c7c7;
}

.containter-search-photo-card {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.subtitle {
  color: #5860699c;
  font-size: 16;
  font-weight: bold;
}

.header {
  color: white;
}

.header-bold {
  font-weight: bold;
}

.container-top-web {
  margin-top: 10rem;
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 10rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
}

.website-link {
  color: #5860699c;
  font-size: 1rem;
  font-weight: bold;
  align-self: center;
}

.top-web .header {
  font-size: 2rem;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  color: #777;
}

.search-box-font {
  font-size: 70;
}

.header-bold-white {
  font-weight: bold;
  color: white;
}

.btn-xl {
  padding: 10px 10px;
  font-size: 20px;
  border-radius: 10px;
  width: 50%;
}

.icon-check {
  padding: 10px 20px;
  font-size: 2rem;
  color: cornflowerblue;
  width: 50%;
}

.icon-delete {
  font-size: 1.2rem;
  margin-left: 5px;
  margin-right: 5px;
  color: crimson;
}

.icon-download {
  padding: 10px 10px;
  font-size: 2rem;
  color: white;
  width: 50%;
}

.icon-x {
  padding: 10px 20px;
  font-size: 2rem;
  color: crimson;
  width: 50%;
}

.container-col-login {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 10rem;
  margin-bottom: 10rem;
  margin-left: 20%;
  margin-right: 20%;
}

.container-row-searchbar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.max-height {
  width: 100%;
  height: 8rem;
  overflow: hidden;
  object-fit: cover;
}

.max-height-30 {
  width: 100%;
  height: 10rem;
  overflow: hidden;
  object-fit: cover;
}

.max-height-100 {
  width: 100%;
  height: 15rem;
  overflow: hidden;
  object-fit: cover;
}

.max-height-500 {
  width: auto;
  height: 600px;
  overflow: hidden;
  object-fit: cover;
}

@media screen and (max-width: 300px) {
  .max-height-500 {
    width: auto;
    height: 150px;
    overflow: hidden;
    object-fit: cover;
  }
}
@media screen and (max-width: 600px) {
  .max-height-500 {
    width: auto;
    height: 300px;
    overflow: hidden;
    object-fit: cover;
  }
}
.max-width {
  width: 10%;
  overflow: visible;
}

.color-box {
  width: 120px;
  height: 30px;
}

div.scrollmenu {
  background-color: white;
  overflow: auto;
  white-space: nowrap;
}

div.scrollmenu a {
  margin-left: 20px;
  font-size: 1.2rem;
  font-weight: light;
  color: #777;
  background-color: white;
  border: 1px solid black;
  border-radius: 5px;
  padding: 0.2rem 1rem;
  transition: ease 0.2s;
}

div.scrollmenu a:hover {
  -webkit-box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}

.container-row-tag {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-right: 5%;
}

.search-box-navbar {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 80%;
}

.search-box-home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100;
}

.container-row-normal-inputbox {
  flex-grow: 1;
  border-color: rgba(0, 0, 0, 0.25);
  border-width: 1px;
  border-radius: 5px;
}

.container-row-normal-inputbox input[type=text] {
  font-size: 15px;
  width: 100%;
  padding: 8px 20px;
}

.textarea {
  font-size: 15px;
  width: 100%;
  padding: 8px 20px;
}

.text-1rem {
  font-size: 1rem;
}

.about .my-masonry-grid {
  display: -webkit-box;
  /* Not needed if autoprefixing */
  display: -ms-flexbox;
  /* Not needed if autoprefixing */
  display: flex;
  width: auto;
}

.about .my-masonry-grid_column {
  background-clip: padding-box;
}

.footer-subtitle {
  color: white;
  font-size: 20;
  font-weight: bold;
}

.footer a {
  color: #c4c4c4;
  font-size: 20;
}

.footer a:hover {
  color: #969696;
}

.footer-container-col {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.footer-container {
  margin-top: 3rem;
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 2rem;
}

.margin-1rem {
  margin: 1rem;
}

.font-1rem {
  font-size: 0.8rem;
}

.more-photos-btn {
  font-size: 1.2rem;
  margin-top: 10%;
  font-weight: light;
  background-color: white;
  border: 1px solid black;
  padding: 0.5rem 1.5rem;
  transition: ease 0.2s;
}

.more-photos-btn:hover {
  -webkit-box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}

.tag-btn {
  margin-left: 20px;
  font-size: 1.2rem;
  font-weight: light;
  background-color: white;
  border: 1px solid black;
  padding: 0.2rem 1rem;
  transition: ease 0.2s;
}

.tag-btn:hover {
  -webkit-box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}

.placeholder {
  font-size: 1.2rem;
  margin-top: 10%;
  display: block;
  color: 144, 196, 212;
  background-color: #90c4d4;
  border: none;
}

.lazyload-img {
  opacity: 0;
}

.delete-btn {
  font-size: 1.2rem;
  margin-top: 10%;
  font-weight: light;
  background-color: white;
  border: 1px solid crimson;
  padding: 0.5rem 1.5rem;
  transition: ease 0.2s;
}

.delete-btn:hover {
  -webkit-box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}

.icon-margin-right {
  margin-right: 5px;
}

.login-info {
  margin: 1rem;
  display: flex;
  align-items: center;
}

/*# sourceMappingURL=index.css.map */
